import React from 'react'

const CircleVortex = ({
  style = {},
  fill = 'none',
  width = 134,
  height = 280,
  viewBox = '0 0 134 280',
  circleFill = 'white',
  circleFillOpacity = 0.05,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M120.659 110.883C121.286 111.737 121.094 112.931 120.231 113.551C119.368 114.171 118.159 113.982 117.532 113.129C116.905 112.275 117.096 111.081 117.96 110.46C118.823 109.84 120.031 110.03 120.659 110.883Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M107.19 128.001C108.204 128.327 108.76 129.404 108.43 130.408C108.1 131.411 107.01 131.96 105.996 131.634C104.981 131.308 104.425 130.231 104.755 129.227C105.085 128.224 106.175 127.675 107.19 128.001Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M106.47 149.677C107.485 149.351 108.575 149.9 108.904 150.903C109.234 151.906 108.679 152.984 107.664 153.31C106.649 153.636 105.559 153.087 105.229 152.084C104.9 151.08 105.455 150.003 106.47 149.677Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M118.774 167.631C119.401 166.777 120.61 166.588 121.473 167.208C122.336 167.828 122.527 169.023 121.9 169.876C121.273 170.73 120.065 170.919 119.201 170.299C118.338 169.679 118.147 168.484 118.774 167.631Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M139.402 175.006C139.402 173.951 140.267 173.095 141.335 173.095C142.402 173.095 143.267 173.951 143.267 175.006C143.267 176.061 142.402 176.916 141.335 176.916C140.267 176.916 139.402 176.061 139.402 175.006Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M160.476 168.984C159.848 168.131 160.04 166.936 160.903 166.316C161.766 165.696 162.975 165.885 163.602 166.739C164.229 167.592 164.038 168.787 163.174 169.407C162.311 170.027 161.103 169.838 160.476 168.984Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M173.945 151.867C172.93 151.541 172.374 150.463 172.704 149.46C173.034 148.456 174.124 147.907 175.139 148.233C176.153 148.559 176.709 149.637 176.379 150.64C176.049 151.644 174.959 152.193 173.945 151.867Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M174.664 130.191C173.649 130.517 172.559 129.968 172.23 128.965C171.9 127.961 172.455 126.884 173.47 126.558C174.485 126.232 175.575 126.781 175.905 127.784C176.235 128.787 175.679 129.865 174.664 130.191Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M162.36 112.237C161.733 113.09 160.525 113.28 159.661 112.659C158.798 112.039 158.607 110.845 159.234 109.991C159.861 109.138 161.069 108.948 161.933 109.569C162.796 110.189 162.987 111.383 162.36 112.237Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M141.732 104.862C141.732 105.917 140.867 106.772 139.8 106.772C138.733 106.772 137.867 105.917 137.867 104.862C137.867 103.807 138.733 102.952 139.8 102.952C140.867 102.952 141.732 103.807 141.732 104.862Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M109.84 106.678C111.176 107.888 111.267 109.94 110.042 111.262C108.818 112.583 106.742 112.672 105.406 111.462C104.07 110.252 103.979 108.199 105.203 106.878C106.428 105.557 108.503 105.467 109.84 106.678Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M95.9369 130.886C97.7376 131.089 99.0311 132.696 98.8261 134.476C98.621 136.257 96.995 137.536 95.1943 137.333C93.3936 137.13 92.1001 135.523 92.3051 133.742C92.5102 131.962 94.1362 130.683 95.9369 130.886Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M99.0814 158.55C100.659 157.668 102.661 158.217 103.554 159.776C104.446 161.336 103.891 163.315 102.314 164.198C100.736 165.08 98.734 164.531 97.8415 162.972C96.9489 161.412 97.5041 159.433 99.0814 158.55Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M118.072 179.103C118.823 177.473 120.769 176.753 122.419 177.496C124.068 178.239 124.796 180.163 124.044 181.794C123.293 183.424 121.347 184.144 119.697 183.401C118.048 182.658 117.32 180.734 118.072 179.103Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M145.655 184.695C145.293 182.939 146.44 181.226 148.216 180.869C149.992 180.511 151.725 181.645 152.086 183.401C152.448 185.157 151.301 186.87 149.525 187.227C147.749 187.585 146.016 186.451 145.655 184.695Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M171.294 173.19C169.958 171.979 169.867 169.927 171.092 168.606C172.316 167.285 174.392 167.195 175.728 168.406C177.064 169.616 177.155 171.668 175.931 172.989C174.707 174.311 172.631 174.4 171.294 173.19Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M185.197 148.982C183.397 148.779 182.103 147.172 182.308 145.391C182.513 143.611 184.139 142.332 185.94 142.535C187.741 142.737 189.034 144.345 188.829 146.125C188.624 147.906 186.998 149.185 185.197 148.982Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M182.053 121.318C180.476 122.201 178.473 121.652 177.581 120.092C176.688 118.533 177.243 116.553 178.821 115.671C180.398 114.788 182.4 115.337 183.293 116.897C184.185 118.456 183.63 120.436 182.053 121.318Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M163.062 100.765C162.311 102.395 160.365 103.115 158.715 102.372C157.066 101.629 156.338 99.7047 157.09 98.0741C157.841 96.4436 159.788 95.724 161.437 96.467C163.086 97.21 163.814 99.1341 163.062 100.765Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M135.479 95.1726C135.841 96.9285 134.694 98.6416 132.918 98.9989C131.142 99.3562 129.409 98.2225 129.048 96.4667C128.687 94.7108 129.833 92.9978 131.609 92.6404C133.385 92.2831 135.118 93.4168 135.479 95.1726Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M95.9973 108.189C98.196 109.38 99.0017 112.108 97.7969 114.281C96.5922 116.455 93.8331 117.252 91.6344 116.061C89.4357 114.869 88.63 112.142 89.8348 109.968C91.0396 107.794 93.7986 106.997 95.9973 108.189Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M85.6365 140.152C88.1234 139.838 90.397 141.577 90.7146 144.036C91.0323 146.494 89.2738 148.742 86.7868 149.056C84.2999 149.37 82.0263 147.632 81.7086 145.173C81.391 142.714 83.1495 140.466 85.6365 140.152Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M96.2574 172.033C98.0827 170.334 100.956 170.419 102.674 172.223C104.393 174.028 104.307 176.869 102.482 178.568C100.656 180.267 97.7834 180.182 96.0646 178.377C94.3458 176.573 94.4322 173.732 96.2574 172.033Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M123.803 191.652C124.269 189.217 126.644 187.616 129.108 188.077C131.571 188.538 133.19 190.886 132.723 193.322C132.257 195.757 129.882 197.358 127.419 196.897C124.955 196.436 123.336 194.088 123.803 191.652Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M157.752 191.517C156.681 189.276 157.651 186.6 159.918 185.542C162.185 184.483 164.891 185.442 165.961 187.684C167.032 189.925 166.062 192.6 163.795 193.659C161.528 194.717 158.822 193.758 157.752 191.517Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M185.137 171.679C182.938 170.488 182.132 167.76 183.337 165.586C184.542 163.412 187.301 162.616 189.5 163.807C191.698 164.998 192.504 167.726 191.299 169.9C190.095 172.073 187.336 172.87 185.137 171.679Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M195.498 139.715C193.011 140.029 190.737 138.291 190.42 135.832C190.102 133.373 191.86 131.125 194.347 130.811C196.834 130.497 199.108 132.236 199.426 134.695C199.743 137.153 197.985 139.401 195.498 139.715Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M184.877 107.835C183.052 109.535 180.179 109.449 178.46 107.645C176.741 105.84 176.828 103 178.653 101.3C180.478 99.6012 183.351 99.6865 185.07 101.491C186.789 103.296 186.702 106.136 184.877 107.835Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M157.331 88.2159C156.865 90.6514 154.49 92.2519 152.027 91.7908C149.563 91.3297 147.944 88.9815 148.411 86.546C148.877 84.1106 151.252 82.51 153.716 82.9711C156.179 83.4322 157.798 85.7804 157.331 88.2159Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M123.382 88.3508C124.453 90.5921 123.483 93.2672 121.216 94.3257C118.949 95.3842 116.243 94.4253 115.173 92.1839C114.102 89.9425 115.072 87.2674 117.339 86.2089C119.606 85.1504 122.312 86.1094 123.382 88.3508Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M80.9327 114.295C84.1882 115.123 86.149 118.402 85.3122 121.621C84.4754 124.84 81.158 126.778 77.9025 125.951C74.647 125.124 72.6863 121.844 73.5231 118.625C74.3598 115.407 77.6772 113.468 80.9327 114.295Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M77.0796 153.847C80.2052 152.625 83.7414 154.139 84.9779 157.229C86.2145 160.319 84.6832 163.815 81.5576 165.038C78.432 166.26 74.8958 164.746 73.6592 161.656C72.4227 158.566 73.954 155.07 77.0796 153.847Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M97.4768 188.085C99.2787 185.28 103.04 184.449 105.877 186.231C108.715 188.012 109.554 191.731 107.753 194.536C105.951 197.342 102.19 198.172 99.3522 196.39C96.5147 194.609 95.675 190.89 97.4768 188.085Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M134.332 203.93C134.122 200.613 136.671 197.756 140.026 197.548C143.381 197.34 146.271 199.861 146.481 203.177C146.691 206.494 144.142 209.351 140.787 209.559C137.433 209.767 134.543 207.247 134.332 203.93Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M173.57 195.331C171.428 192.77 171.792 188.977 174.382 186.859C176.973 184.741 180.809 185.101 182.951 187.662C185.093 190.223 184.729 194.016 182.139 196.134C179.548 198.251 175.712 197.892 173.57 195.331Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M200.201 165.572C196.946 164.745 194.985 161.465 195.822 158.246C196.659 155.028 199.976 153.089 203.232 153.916C206.487 154.744 208.448 158.023 207.611 161.242C206.774 164.461 203.457 166.399 200.201 165.572Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M204.055 126.02C200.929 127.243 197.393 125.729 196.156 122.638C194.92 119.548 196.451 116.052 199.577 114.83C202.702 113.607 206.238 115.121 207.475 118.211C208.711 121.301 207.18 124.798 204.055 126.02Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M183.658 91.7832C181.856 94.5886 178.095 95.4188 175.258 93.6373C172.42 91.8559 171.58 88.1375 173.382 85.3321C175.184 82.5266 178.945 81.6965 181.783 83.4779C184.62 85.2593 185.46 88.9777 183.658 91.7832Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M146.802 75.9378C147.012 79.2546 144.463 82.1118 141.108 82.3196C137.753 82.5274 134.863 80.0071 134.653 76.6903C134.443 73.3736 136.992 70.5164 140.347 70.3086C143.702 70.1008 146.592 72.6211 146.802 75.9378Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M107.564 84.5369C109.706 87.098 109.343 90.8909 106.752 93.0086C104.162 95.1262 100.325 94.7667 98.1834 92.2056C96.0415 89.6444 96.4051 85.8515 98.9956 83.7338C101.586 81.6162 105.422 81.9757 107.564 84.5369Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M67.1056 125.494C71.3885 125.516 74.8431 128.965 74.8217 133.2C74.8002 137.434 71.3109 140.85 67.028 140.828C62.7451 140.807 59.2905 137.357 59.3119 133.123C59.3334 128.889 62.8227 125.473 67.1056 125.494Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M72.5512 170.943C76.0287 168.471 80.8745 169.254 83.3746 172.692C85.8747 176.13 85.0823 180.921 81.6048 183.393C78.1272 185.865 73.2814 185.082 70.7813 181.643C68.2812 178.205 69.0736 173.414 72.5512 170.943Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M103.977 204.547C105.321 200.527 109.707 198.344 113.774 199.673C117.84 201.002 120.048 205.338 118.704 209.359C117.36 213.379 112.974 215.562 108.907 214.233C104.84 212.904 102.633 208.568 103.977 204.547Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M149.378 213.471C148.075 209.437 150.326 205.123 154.406 203.834C158.486 202.546 162.85 204.771 164.153 208.805C165.456 212.839 163.205 217.153 159.125 218.442C155.045 219.73 150.681 217.504 149.378 213.471Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M191.414 194.306C187.962 191.8 187.218 187.001 189.753 183.588C192.288 180.175 197.141 179.44 200.593 181.946C204.046 184.452 204.79 189.25 202.255 192.663C199.72 196.077 194.867 196.812 191.414 194.306Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M214.028 154.373C209.746 154.352 206.291 150.902 206.312 146.668C206.334 142.433 209.823 139.018 214.106 139.039C218.389 139.06 221.844 142.51 221.822 146.744C221.801 150.979 218.311 154.394 214.028 154.373Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M208.583 108.925C205.105 111.396 200.26 110.613 197.76 107.175C195.259 103.737 196.052 98.9457 199.529 96.4739C203.007 94.0021 207.853 94.7855 210.353 98.2237C212.853 101.662 212.061 106.453 208.583 108.925Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M177.158 75.321C175.814 79.3416 171.428 81.5239 167.361 80.1952C163.295 78.8665 161.088 74.5301 162.431 70.5095C163.775 66.4889 168.161 64.3066 172.228 65.6353C176.295 66.9639 178.502 71.3004 177.158 75.321Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M131.756 66.397C133.059 70.4307 130.808 74.7451 126.728 76.0334C122.649 77.3218 118.285 75.0962 116.982 71.0625C115.679 67.0288 117.93 62.7144 122.009 61.4261C126.089 60.1377 130.453 62.3633 131.756 66.397Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M89.7202 85.5619C93.1725 88.0679 93.9163 92.8665 91.3816 96.2797C88.8468 99.693 83.9933 100.428 80.541 97.9223C77.0886 95.4163 76.3448 90.6177 78.8796 87.2045C81.4143 83.7912 86.2678 83.0558 89.7202 85.5619Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M55.2236 140.669C60.3296 139.513 65.4167 142.668 66.5859 147.716C67.7552 152.765 64.5639 157.794 59.4579 158.95C54.3519 160.106 49.2648 156.951 48.0956 151.903C46.9263 146.855 50.1176 141.825 55.2236 140.669Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M71.96 190.124C75.4036 186.222 81.3949 185.818 85.3421 189.222C89.2893 192.627 89.6976 198.551 86.254 202.453C82.8105 206.356 76.8191 206.759 72.872 203.355C68.9248 199.95 68.5165 194.027 71.96 190.124Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M114.903 220.409C115.369 215.25 119.976 211.442 125.193 211.903C130.411 212.363 134.263 216.918 133.797 222.076C133.331 227.235 128.724 231.043 123.506 230.583C118.289 230.122 114.437 225.567 114.903 220.409Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M167.647 219.954C164.957 215.51 166.42 209.751 170.915 207.092C175.41 204.433 181.234 205.879 183.924 210.323C186.614 214.767 185.151 220.525 180.656 223.185C176.161 225.844 170.337 224.398 167.647 219.954Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M210.048 188.934C205.23 186.902 202.991 181.393 205.046 176.63C207.101 171.866 212.673 169.652 217.491 171.684C222.309 173.716 224.549 179.225 222.494 183.988C220.439 188.752 214.867 190.966 210.048 188.934Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M225.91 139.198C220.804 140.354 215.717 137.199 214.548 132.151C213.379 127.103 216.57 122.073 221.676 120.917C226.782 119.761 231.869 122.916 233.038 127.964C234.208 133.013 231.016 138.042 225.91 139.198Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M209.174 89.7427C205.73 93.6452 199.739 94.0489 195.792 90.6443C191.844 87.2397 191.436 81.3162 194.88 77.4137C198.323 73.5112 204.315 73.1076 208.262 76.5121C212.209 79.9167 212.617 85.8402 209.174 89.7427Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M166.232 59.4592C165.767 64.6176 161.159 68.4259 155.942 67.9654C150.725 67.5049 146.873 62.9499 147.338 57.7916C147.804 52.6332 152.411 48.8249 157.629 49.2854C162.846 49.7459 166.698 54.3009 166.232 59.4592Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M113.487 59.9138C116.177 64.3576 114.714 70.116 110.219 72.7754C105.724 75.4348 99.9001 73.9883 97.2102 69.5444C94.5203 65.1006 95.9834 59.3422 100.478 56.6828C104.973 54.0233 110.797 55.4699 113.487 59.9138Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M71.0859 90.9336C75.904 92.9656 78.1437 98.4745 76.0885 103.238C74.0332 108.002 68.4613 110.216 63.6431 108.184C58.825 106.152 56.5853 100.643 58.6406 95.8796C60.6958 91.116 66.2678 88.9016 71.0859 90.9336Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M46.8787 163.271C52.18 160.652 58.6253 162.777 61.2747 168.018C63.9241 173.26 61.7743 179.632 56.4731 182.251C51.1718 184.871 44.7265 182.745 42.0771 177.504C39.4277 172.263 41.5775 165.891 46.8787 163.271Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M78.604 213.217C81.3355 208.017 87.8134 205.991 93.0728 208.692C98.3323 211.392 100.382 217.797 97.65 222.997C94.9185 228.196 88.4405 230.223 83.1811 227.522C77.9217 224.821 75.8724 218.417 78.604 213.217Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M133.967 235.167C133.085 229.372 137.122 223.969 142.982 223.097C148.843 222.226 154.308 226.216 155.19 232.01C156.071 237.805 152.035 243.208 146.175 244.08C140.314 244.951 134.849 240.961 133.967 235.167Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M191.805 220.815C187.647 216.64 187.7 209.922 191.923 205.812C196.146 201.701 202.94 201.753 207.098 205.928C211.256 210.103 211.203 216.821 206.98 220.932C202.757 225.042 195.963 224.99 191.805 220.815Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M230.077 175.539C224.231 174.578 220.281 169.113 221.253 163.333C222.226 157.553 227.753 153.647 233.599 154.608C239.446 155.57 243.396 161.035 242.424 166.815C241.451 172.595 235.924 176.501 230.077 175.539Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M234.091 116.712C228.79 119.332 222.345 117.206 219.695 111.965C217.046 106.724 219.196 100.352 224.497 97.7321C229.798 95.1127 236.243 97.2381 238.893 102.479C241.542 107.721 239.392 114.093 234.091 116.712Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M202.382 66.755C199.65 71.9549 193.172 73.9809 187.913 71.2803C182.654 68.5797 180.604 62.1751 183.336 56.9752C186.067 51.7754 192.545 49.7493 197.805 52.4499C203.064 55.1505 205.113 61.5551 202.382 66.755Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M147.026 44.7756C147.907 50.5698 143.871 55.9734 138.011 56.845C132.15 57.7166 126.685 53.726 125.803 47.9319C124.921 42.1377 128.958 36.734 134.818 35.8625C140.679 34.9909 146.144 38.9814 147.026 44.7756Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M89.1658 59.1687C93.3237 63.3439 93.2708 70.0612 89.0478 74.172C84.8247 78.2828 78.0305 78.2306 73.8726 74.0553C69.7147 69.8801 69.7675 63.1628 73.9906 59.052C78.2137 54.9412 85.0078 54.9934 89.1658 59.1687Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M50.9398 104.412C56.7859 105.374 60.7366 110.839 59.764 116.618C58.7915 122.398 53.2638 126.304 47.4177 125.343C41.5716 124.381 37.6209 118.916 38.5935 113.136C39.5661 107.356 45.0937 103.45 50.9398 104.412Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M45.6379 189.778C50.5871 185.679 57.9607 186.322 62.1073 191.215C66.2539 196.108 65.6032 203.398 60.6539 207.498C55.7047 211.598 48.331 210.954 44.1845 206.061C40.0379 201.168 40.6886 193.878 45.6379 189.778Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M93.3549 235.385C94.9216 229.193 101.269 225.428 107.533 226.977C113.797 228.526 117.605 234.802 116.038 240.995C114.471 247.188 108.123 250.952 101.86 249.403C95.5958 247.854 91.7881 241.578 93.3549 235.385Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M159.07 244.527C156.656 238.607 159.554 231.872 165.542 229.485C171.53 227.098 178.342 229.963 180.756 235.884C183.17 241.804 180.273 248.539 174.285 250.926C168.296 253.312 161.485 250.448 159.07 244.527Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M217.685 213.801C212.212 210.414 210.552 203.282 213.978 197.871C217.404 192.46 224.617 190.819 230.091 194.206C235.564 197.593 237.223 204.725 233.798 210.136C230.372 215.547 223.158 217.188 217.685 213.801Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M246.84 154.823C240.399 155.263 234.816 150.458 234.37 144.09C233.925 137.721 238.785 132.201 245.227 131.761C251.668 131.32 257.251 136.126 257.697 142.494C258.142 148.863 253.282 154.382 246.84 154.823Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M235.339 90.2183C230.389 94.3179 223.016 93.6746 218.869 88.7813C214.723 83.8881 215.373 76.598 220.322 72.4984C225.272 68.3987 232.645 69.0421 236.792 73.9353C240.938 78.8285 240.288 86.1187 235.339 90.2183Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M187.635 44.5969C186.068 50.7898 179.72 54.5543 173.457 53.0053C167.193 51.4563 163.385 45.1803 164.952 38.9875C166.519 32.7947 172.867 29.0301 179.13 30.5791C185.394 32.1281 189.202 38.4041 187.635 44.5969Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M121.919 35.4211C124.334 41.3417 121.436 48.0762 115.448 50.4631C109.46 52.85 102.648 49.9854 100.234 44.0648C97.8194 38.1442 100.717 31.4097 106.705 29.0228C112.694 26.6359 119.505 29.5006 121.919 35.4211Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M63.2911 66.1955C68.7642 69.5823 70.4239 76.7145 66.9982 82.1255C63.5726 87.5366 56.3587 89.1775 50.8857 85.7906C45.4127 82.4037 43.753 75.2716 47.1787 69.8606C50.6043 64.4495 57.8181 62.8086 63.2911 66.1955Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M34.1782 125.129C40.6195 124.689 46.2024 129.494 46.648 135.863C47.0935 142.231 42.233 147.751 35.7916 148.191C29.3503 148.632 23.7674 143.826 23.3219 137.458C22.8763 131.09 27.7369 125.57 34.1782 125.129Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M51.0457 218.187C55.2685 212.644 63.2371 211.534 68.8441 215.709C74.4511 219.884 75.5732 227.762 71.3504 233.306C67.1277 238.85 59.1591 239.959 53.5521 235.784C47.9451 231.609 46.8229 223.731 51.0457 218.187Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M114.615 255.228C114.736 248.289 120.523 242.761 127.541 242.88C134.559 242.999 140.151 248.721 140.03 255.66C139.91 262.598 134.123 268.127 127.104 268.007C120.086 267.888 114.495 262.167 114.615 255.228Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M188.056 248.225C184.028 242.542 185.424 234.706 191.172 230.724C196.921 226.742 204.846 228.121 208.874 233.805C212.902 239.489 211.507 247.324 205.758 251.306C200.009 255.289 192.084 253.909 188.056 248.225Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M243.341 199.951C236.704 197.693 233.174 190.543 235.457 183.981C237.741 177.419 244.973 173.929 251.61 176.187C258.248 178.444 261.777 185.594 259.494 192.156C257.21 198.719 249.979 202.209 243.341 199.951Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M259.355 128.705C252.643 130.736 245.537 127.003 243.483 120.367C241.429 113.731 245.205 106.705 251.917 104.674C258.629 102.643 265.735 106.376 267.789 113.012C269.843 119.649 266.067 126.674 259.355 128.705Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M229.94 61.8234C225.717 67.3669 217.749 68.4764 212.142 64.3014C206.535 60.1264 205.413 52.248 209.635 46.7045C213.858 41.161 221.827 40.0516 227.434 44.2265C233.041 48.4015 234.163 56.2799 229.94 61.8234Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M166.382 24.7641C166.261 31.7029 160.474 37.2312 153.456 37.112C146.437 36.9928 140.846 31.2712 140.966 24.3324C141.087 17.3936 146.874 11.8652 153.892 11.9844C160.911 12.1037 166.502 17.8253 166.382 24.7641Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M92.9321 31.7297C96.9598 37.4134 95.5645 45.249 89.8158 49.2311C84.067 53.2132 76.1417 51.8337 72.114 46.1501C68.0863 40.4664 69.4816 32.6308 75.2303 28.6487C80.9791 24.6666 88.9044 26.0461 92.9321 31.7297Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M37.6446 80.0599C44.2821 82.3175 47.8117 89.4674 45.5283 96.0298C43.2448 102.592 36.013 106.082 29.3755 103.824C22.738 101.567 19.2084 94.4167 21.4918 87.8543C23.7753 81.292 31.0071 77.8023 37.6446 80.0599Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M21.6649 151.247C28.3769 149.216 35.4833 152.95 37.5373 159.586C39.5914 166.222 35.8154 173.247 29.1034 175.278C22.3914 177.309 15.2851 173.576 13.231 166.94C11.1769 160.304 14.9529 153.278 21.6649 151.247Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M64.8931 245.335C67.9958 238.389 76.2065 235.245 83.2322 238.313C90.2579 241.38 93.4381 249.498 90.3354 256.444C87.2327 263.39 79.0221 266.534 71.9964 263.467C64.9707 260.399 61.7905 252.282 64.8931 245.335Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M141.954 269.147C140.335 261.725 145.108 254.409 152.616 252.808C160.123 251.207 167.522 255.926 169.142 263.349C170.761 270.772 165.988 278.087 158.48 279.688C150.973 281.289 143.574 276.57 141.954 269.147Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M218.438 243.603C212.715 238.539 212.227 229.847 217.349 224.189C222.471 218.53 231.263 218.048 236.986 223.112C242.709 228.176 243.196 236.868 238.074 242.527C232.952 248.185 224.161 248.667 218.438 243.603Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M265.181 178.555C257.54 177.784 251.978 171.036 252.758 163.482C253.538 155.927 260.364 150.429 268.004 151.2C275.645 151.971 281.207 158.719 280.427 166.273C279.647 173.828 272.821 179.326 265.181 178.555Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M264.295 98.7033C257.655 102.52 249.144 100.292 245.283 93.7275C241.423 87.1629 243.676 78.7474 250.316 74.9309C256.956 71.1144 265.468 73.3422 269.328 79.9067C273.188 86.4713 270.935 94.8868 264.295 98.7033Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M216.107 34.6871C213.004 41.6333 204.794 44.7775 197.768 41.7099C190.742 38.6424 187.562 30.5247 190.665 23.5785C193.767 16.6323 201.978 13.4881 209.004 16.5557C216.029 19.6233 219.21 27.741 216.107 34.6871Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M139.053 10.8527C140.672 18.2754 135.899 25.5906 128.391 27.1917C120.884 28.7928 113.485 24.0736 111.865 16.651C110.246 9.22833 115.019 1.91313 122.527 0.311992C130.034 -1.28914 137.433 3.43011 139.053 10.8527Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M62.5507 36.3603C68.2738 41.4242 68.7611 50.1163 63.6392 55.7745C58.5173 61.4328 49.7257 61.9146 44.0027 56.8507C38.2797 51.7868 37.7923 43.0948 42.9142 37.4365C48.0361 31.7782 56.8277 31.2964 62.5507 36.3603Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M15.8194 101.468C23.46 102.239 29.0218 108.988 28.2421 116.542C27.4623 124.096 20.6362 129.595 12.9956 128.824C5.35497 128.053 -0.206841 121.304 0.572935 113.75C1.35271 106.196 8.17879 100.697 15.8194 101.468Z"
    />
    <path
      fill={circleFill}
      fillOpacity={circleFillOpacity}
      d="M16.7266 181.249C23.3663 177.432 31.8782 179.66 35.7384 186.225C39.5986 192.789 37.3453 201.205 30.7056 205.021C24.0659 208.838 15.554 206.61 11.6938 200.045C7.83361 193.481 10.0869 185.065 16.7266 181.249Z"
    />
  </svg>
)

export default CircleVortex
