import * as T from './types'

interface ICheckmarkColor {
  checkmarkColor?: string
}

export const RoundedCheckmarkCircleFilled = (props: T.ISVGProps & ICheckmarkColor) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
      fill={props.fill}
    />
    <path
      d="M15.7729 6.83337L10.0687 14.5742C9.9327 14.7547 9.72985 14.873 9.50576 14.9024C9.28167 14.9319 9.05514 14.8701 8.87708 14.7309L4.80374 11.4742C4.4443 11.1866 4.38609 10.662 4.67374 10.3025C4.96139 9.94309 5.48596 9.88489 5.84541 10.1725L9.24208 12.89L14.4312 5.84754C14.6014 5.5922 14.8981 5.45103 15.2036 5.48007C15.5091 5.50912 15.7738 5.70368 15.8928 5.98652C16.0118 6.26935 15.9657 6.59469 15.7729 6.83337Z"
      fill={props.checkmarkColor || 'white'}
    />
  </svg>
)

export default RoundedCheckmarkCircleFilled
